import { Component } from '@angular/core';
import { NavController } from '@ionic/angular';
import { GlobalService } from './global.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Login', url: '/login', icon: 'person-circle' }
  ];
  
  constructor(public global: GlobalService, public nav: NavController) {}

  Guardar(item){
    console.log(item);
    this.nav.navigateRoot([item]);
  }

}
